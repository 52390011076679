import React, {Component} from "react";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faLink} from "@fortawesome/free-solid-svg-icons";
// Loading Assets (SubComponents & CSS)
import Editor from "./Editor";
import "../css/Selector.css";
import headElements from "../library/heads.json";
import beardElements from "../library/beard.json";
import beardElements2 from "../library/beard2.json";
import beardElements3 from "../library/beard3.json";
import beardElements4 from "../library/beard4.json";
import beardElements5 from "../library/beard5.json";
import beardElements6 from "../library/beard6.json";
import wideBeardElements from "../library/beardWide.json";
import wideBeardElements2 from "../library/beardwide2.json";
import wideBeardElements3 from "../library/beardwide3.json";
import wideBeardElements4 from "../library/beardwide4.json";
import wideBeardElements5 from "../library/beardwide5.json";
import wideBeardElements6 from "../library/beardwide6.json";
import hairElements from "../library/hair.json";
import hairElements2 from "../library/hair2.json";
import hairElements3 from "../library/hair3.json";
import hairElements4 from "../library/hair4.json";
import hairElements5 from "../library/hair5.json";
import hairElements6 from "../library/hair6.json";
import wideHairElements from "../library/hairwide.json";
import wideHairElements2 from "../library/hairwide2.json";
import wideHairElements3 from "../library/hairwide3.json";
import wideHairElements4 from "../library/hairwide4.json";
import wideHairElements5 from "../library/hairwide5.json";
import wideHairElements6 from "../library/hairwide6.json";
import eyebrowElements from "../library/eyebrow.json";
import eyebrowElements2 from "../library/eyebrow2.json";
import eyebrowElements3 from "../library/eyebrow3.json";
import eyebrowElements4 from "../library/eyebrow4.json";
import eyebrowElements5 from "../library/eyebrow5.json";
import eyebrowElements6 from "../library/eyebrow6.json";
import eyeElements from "../library/eye.json";
import eyeElements2 from "../library/eye2.json";
import eyeElements3 from "../library/eye3.json";
import eyeElements4 from "../library/eye4.json";
import eyeElements5 from "../library/eye5.json";
import eyeElements6 from "../library/eye6.json";
import lipElements from "../library/lip.json";
import lipElements2 from "../library/lip2.json";
import lipElements3 from "../library/lip3.json";
import lipElements4 from "../library/lip4.json";
import lipElements5 from "../library/lip5.json";
import lipElements6 from "../library/lip6.json";
import noseElements from "../library/nose.json";
import noseElements2 from "../library/nose2.json";
import noseElements3 from "../library/nose3.json";
import noseElements4 from "../library/nose4.json";
import noseElements5 from "../library/nose5.json";
import noseElements6 from "../library/nose6.json";
import narrowGlassesElements1 from "../library/glassesNarrow1.json";
import narrowGlassesElements2 from "../library/glassesNarrow2.json";
import narrowGlassesElements3 from "../library/glassesNarrow3.json";
import narrowGlassesElements4 from "../library/glassesNarrow4.json";
import narrowGlassesElements5 from "../library/glassesNarrow5.json";
import narrowGlassesElements6 from "../library/glassesNarrow6.json";
import wideGlassesElements1 from "../library/glassesWide1.json";
import wideGlassesElements2 from "../library/glassesWide2.json";
import wideGlassesElements3 from "../library/glassesWide3.json";
import wideGlassesElements4 from "../library/glassesWide4.json";
import wideGlassesElements5 from "../library/glassesWide5.json";
import wideGlassesElements6 from "../library/glassesWide6.json";
import hatAllElements from "../library/hatall.json";
import baldHatElements from "../library/hatbald.json";
import torsoElements from "../library/torso.json";
import footElements from "../library/foot.json";
import standElements from "../library/stands.json";
import poseElements from "../library/poses.json";
import bones from "../library/bones.json";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import * as THREE from 'three';
import MainStage from './webgl/MainStage'
import PageLoader from "./PageLoader";
import PartLoader from "./PartLoader";

const headDefaults = {
    "ava_head_narrow_oblong": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_oblong_narrow_hair_messy",
        currentNose: "ava_head_oblong_nose_delicate",
        currentEye: "ava_head_oblong_eyes_v1",
        currentEyebrow: "ava_head_oblong_eyebrows_arched",
        currentLip: "ava_head_oblong_lips_BowShaped",
    },
    "ava_head_narrow_teardrop": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_teardrop_narrow_hair_messy",
        currentNose: "ava_head_teardrop_nose_delicate",
        currentEye: "ava_head_teardrop_eyes_v1",
        currentEyebrow: "ava_head_teardrop_eyebrows_arched",
        currentLip: "ava_head_teardrop_lips_BowShaped",
    },
    "ava_head_narrow_kite": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_kite_narrow_hair_messy",
        currentNose: "ava_head_kite_nose_delicate",
        currentEye: "ava_head_kite_eyes_v1",
        currentEyebrow: "ava_head_kite_eyebrows_arched",
        currentLip: "ava_head_kite_lips_BowShaped",
    },
    "ava_head_narrow_round": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_round_narrow_hair_longstraight",
        currentNose: "ava_head_round_nose_delicate",
        currentEye: "ava_head_round_eyes_v1",
        currentEyebrow: "ava_head_round_eyebrows_arched",
        currentLip: "ava_head_round_lips_BowShaped",
    },
    "ava_head_narrow_square": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_square_narrow_hair_longstraight",
        currentNose: "ava_head_square_nose_delicate",
        currentEye: "ava_head_square_eyes_v1",
        currentEyebrow: "ava_head_square_eyebrows_arched",
        currentLip: "ava_head_square_lips_BowShaped",
    },
    "ava_head_narrow_rectangle": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_rectangle_narrow_hair_longstraight",
        currentNose: "ava_head_rectangle_nose_delicate",
        currentEye: "ava_head_rectangle_eyes_v1",
        currentEyebrow: "ava_head_rectangle_eyebrows_arched",
        currentLip: "ava_head_rectangle_lips_BowShaped",
    },
    "ava_head_wide_oblong": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_oblong_wide_hair_messy",
        currentNose: "ava_head_oblong_nose_delicate",
        currentEye: "ava_head_oblong_eyes_v1",
        currentEyebrow: "ava_head_oblong_eyebrows_arched",
        currentLip: "ava_head_oblong_lips_BowShaped",
    },
    "ava_head_wide_teardrop": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_teardrop_wide_hair_messy",
        currentNose: "ava_head_teardrop_nose_delicate",
        currentEye: "ava_head_teardrop_eyes_v1",
        currentEyebrow: "ava_head_teardrop_eyebrows_arched",
        currentLip: "ava_head_teardrop_lips_BowShaped",
    },
    "ava_head_wide_kite": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_kite_wide_hair_messy",
        currentNose: "ava_head_kite_nose_delicate",
        currentEye: "ava_head_kite_eyes_v1",
        currentEyebrow: "ava_head_kite_eyebrows_arched",
        currentLip: "ava_head_kite_lips_BowShaped",
    },
    "ava_head_wide_round": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_round_wide_hair_longstraight",
        currentNose: "ava_head_round_nose_delicate",
        currentEye: "ava_head_round_eyes_v1",
        currentEyebrow: "ava_head_round_eyebrows_arched",
        currentLip: "ava_head_round_lips_BowShaped",
    },
    "ava_head_wide_square": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_square_wide_hair_longstraight",
        currentNose: "ava_head_square_nose_delicate",
        currentEye: "ava_head_square_eyes_v1",
        currentEyebrow: "ava_head_square_eyebrows_arched",
        currentLip: "ava_head_square_lips_BowShaped",
    },
    "ava_head_wide_rectangle": {
        currentBeard: "Ava_Dot",
        currentHair: "ava_head_rectangle_wide_hair_longstraight",
        currentNose: "ava_head_rectangle_nose_delicate",
        currentEye: "ava_head_rectangle_eyes_v1",
        currentEyebrow: "ava_head_rectangle_eyebrows_arched",
        currentLip: "ava_head_rectangle_lips_BowShaped",
    },
};

const dataml = {
    "status": "success",
    "response": {
        "matches": {
            "colors": {
                "face": "#E7AA92",
                "hair": "#C8C8D7",
                "nose": "#E7AA92",
                "facialhair": "#E7AA92",
                "eyebrow": "#C5B7AE"
            },
            "facial_features": {
                "lips": "ava_head_oblong_lips_heavylower",
                "head": "ava_head_narrow_oblong",
                "eyebrows": "ava_head_oblong_eyebrows_arched",
                "nose": "ava_head_oblong_nose_grecian",
                "facialhair": "ava_head_oblong_narrow_facialhair_Ava_Dot",
                "hair": "ava_head_oblong_narrow_female_longhair",
                "eyes": "ava_head_oblong_eyes_v1"
            }
        },
        "description": "The individual in the image is female, featuring hair that is medium-length, falling just below the shoulders with subtle waves. The hair is parted in the middle, framing the face softly. The texture appears to be silky and healthy, with a natural shine. There are no visible bangs; instead, the strands from the front blend seamlessly with the rest of the hair. The overall silhouette is balanced, without extreme styling such as shaved sides or heavy layering. \n\nShe has no facial hair, giving her a smooth appearance. Her eyebrows are medium in thickness and display a gentle arch, lending expressiveness to her features. \n\nHer nose is delicate, characterized by a slightly rounded tip and a straight bridge. It has a modest width, creating a harmonious balance with her other facial features. \n\nThe eyes are almond-shaped and expressive, and they appear to be double-lidded, highlighted by long, thick eyelashes that frame them beautifully. She is not wearing glasses. Her face has a soft, rounded shape, contributing to her youthful and approachable look. \n\nThe skin tone is light and even, with a natural flush on the cheeks that enhances her vitality."
    }
}

class Selector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorSelected: false,
            pose: undefined,
            search: "",
            selectedBodyType: null,
            selectedHeadType: null,
            currentBodyTypeIndex: null,
            currentHeadTypeIndex: null,
            selectedHeadModel: null,
            currentStep: 'torso',
            currentStepIndex: 1,
            selectedElements: {},
            imageFiles: [],
            customModelUpload: false,
            avatarmodel: false,
            partLoading: false,
            currentHair: null,
        }
        this.closeModel = true;
        this.notInited = true;
        this.loader = null;
        this.loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        this.avatar = new THREE.Group();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath('https://mamaar.ai/draco/');
        this.loader.setDRACOLoader(dracoLoader);
    }

    updateSearchValue = search => {
        this.setState({search});
    };

    // Update the progress bar and current step
    updateProgress = (stepIndex) => {
        this.setState({ currentStepIndex: stepIndex });
    };

    avatarModel = () => {
        this.setState((prevState) => ({ avatarmodel: !prevState.avatarmodel }));
        this.setState({ currentStep: 'imageupload' });
    };

    setCustomModelUpload = (value) => {
        this.setState({ customModelUpload: value });
    };

    // Method to handle image upload
    handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file && this.state.imageFiles.length < 2) {
            this.setState((prevState) => ({
              imageFiles: [...prevState.imageFiles, file], // Add file to array
            }));
            // this.closeModel = false;
            this.setCustomModelUpload(true);
    
            // this.createAvatar(file).finally(() => {
            //     this.setCustomModelUpload(false); // Reset customModelUpload after loading
            // });
        }
    };

    // Method to call ML endpoint
    createAvatar = async (selectedHeadType) => {
        // this.props.updateLoading(true);
        this.setState({ partLoading: true });
        const formData = new FormData();
        this.state.imageFiles.forEach((file) => {
            formData.append(`images`, file); // Add each image file
          });
          formData.append('HeadType', selectedHeadType);
        try {
            const response = await axios.post('https://mamaar.ai/ml/create_avatar_via_image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('response from ml:', response.data)
            if (response.data.status === 'success') {
                const avatarData = response.data.response.matches;
                await this.applyAvatarComponents(avatarData, selectedHeadType);
            }
            // this.props.updateLoading(false);
            this.handleCloseModel();
        } catch (error) {
            console.error('Error creating avatar:', error);
            // this.props.updateLoading(false);
        } finally {
            this.setState({ partLoading: false });
            // this.props.updateLoading(false);
        }
    };

    handlecolorchange = (meshName, color) => {
        if (!meshName || !color) return;
        console.log("+++ meshName ++++", meshName, "+++ color +++ ", color);
        const { mainStage } = this.props;
        // headmesh = data.facial_features.head; 
        // let color = "#FFFFFF"
        
        // const rgbColor = this.hexToRgb(color);
        // const gamma = 2.2;
        // const normalizedColor = {
        //     r: Math.pow(rgbColor.r, gamma),
        //     g: Math.pow(rgbColor.g, gamma),
        //     b: Math.pow(rgbColor.b, gamma),
        // };

        let r = parseInt(color.slice(1, 3), 16) / 255;
        let g = parseInt(color.slice(3, 5), 16) / 255;
        let b = parseInt(color.slice(5, 7), 16) / 255;
    
        const gamma = 2.2;
        const normalizedColor = {
            r: Math.pow(r, gamma),
            g: Math.pow(g, gamma),
            b: Math.pow(b, gamma),
        };
        // Update the mesh with the specified color
        if (mainStage) {
            console.log("HandleColorChange COLOR UPloaded, :", normalizedColor)
            if (meshName === "NoseModel") {
                mainStage.changeColor("mesh-head", normalizedColor);
                mainStage.changeColor("NoseModel", normalizedColor);
            } else if (meshName === "mesh-footl" || meshName === "mesh-footr") {
                mainStage.changeColor("mesh-footl", normalizedColor);
                mainStage.changeColor("mesh-footr", normalizedColor);
            } else {
                mainStage.changeColor(meshName, normalizedColor);
            }
        }         
    }

    hexToRgb(hex) {
        let r = 0, g = 0, b = 0;
        if (hex.length === 7) {
            r = parseInt(hex.slice(1, 3), 16) / 255;
            g = parseInt(hex.slice(3, 5), 16) / 255;
            b = parseInt(hex.slice(5, 7), 16) / 255;
        } else if (hex.length === 4) {
            r = parseInt(hex.slice(1, 2) + hex.slice(1, 2), 16) / 255;
            g = parseInt(hex.slice(2, 3) + hex.slice(2, 3), 16) / 255;
            b = parseInt(hex.slice(3, 4) + hex.slice(3, 4), 16) / 255;
        }
        return { r, g, b, a: 1 };
    }
    // createAvatar = async (selectedHeadType) => {
    //     try {
    //         this.setState({ partLoading: true });
    //         // Read the avatar response from the JSON file
    //         const data = dataml
    //         const response = data; // Parse the JSON string into an object

    //         console.log('Response from JSON file:', response);

    //         if (response.status === 'success') {
    //             const avatarData = response.response.matches;
    //             await this.applyAvatarComponents(avatarData, selectedHeadType);
    //         }
    //         this.handleCloseModel();
    //         return response; // Return the response if needed
    //     } catch (error) {
    //         this.setState({ partLoading: false }); 
    //         console.error('Error reading avatar response:', error);
    //         throw new Error('Could not read avatar response');
    //     } finally {
    //         this.setState({ partLoading: false });
    //     }
    // }

    // Update loading method
    updateLoading = (loading) => {
        this.setState({ partLoading: loading });
    };

        // Define loadComponent within Selector
        loadComponent = (category, file, libraryGetter) => {

            const library = libraryGetter();
            const modelData = library.find((model) => model.file === file);
            console.log(`models + for ${category}:`, "library [", library, "]");
            if (modelData) {
                console.log(`Loading component for ${category}:`, modelData);
                // Example placeholder for where you'd load and apply the model:
                window.changeMesh(category, modelData, false, [], this.state.pose); // Custom logic for your app
            } else {
                console.log(`found component for ${category}:`, modelData);
                console.warn(`Model for ${file} not found in ${category} library`);
            }
        };

        loadComponentWithLoader = async (category, file, libraryGetter) => {
            this.props.updateLoading(true);  // Show loader before loading component
            await new Promise((resolve) => {
                setTimeout(() => {
                    this.loadComponent(category, file, libraryGetter);  // Load component
                    this.props.updateLoading(false);  // Hide loader after component is loaded
                    resolve();
                }, 1000);  // Adjust the delay as needed
            });
        };

     applyAvatarComponents = async (data, selectedHeadType) => {
        const bones = [];
        const isLeft = false;

        // Load head
        const headLibrary = this.getHeadLibraryByType(selectedHeadType);
        const headModel = headLibrary.find((item) => item.file === data.facial_features.head);
        if (headModel) {
            await this.loadComponentWithLoader("head", data.facial_features.head, () => [headModel]);
        }

        // Sequentially load each face feature
        await this.loadComponentWithLoader("lip", data.facial_features.lips, () => this.getLipLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("eye", data.facial_features.eyes, () => this.getEyeLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("eyebrow", data.facial_features.eyebrows, () => this.getEyebrowLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("beard", data.facial_features.facialhair, () => this.getBeardLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("hair", data.facial_features.hair, () => this.getHairLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("nose", data.facial_features.nose, () => this.getNoseLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("glasses", data.facial_features.glasses, () => this.getGlassesLibrary(data.facial_features.head));
        await this.loadComponentWithLoader("hat", data.facial_features.headgear, () => this.getHatLibrary(data.facial_features.headgear));
        // mainstage.changeColor("EyeBrowModel", "{r: 1, g:1, b:1}");
        this.handlecolorchange("GlassesModel", data.colors.glasses);
        this.handlecolorchange("EyeBrowModel", data.colors.eyebrow);
        this.handlecolorchange("NoseModel", data.colors.nose);
        this.handlecolorchange("HairModel", data.colors.hair);
        this.handlecolorchange("BeardModel", data.colors.facialhair);
        this.handlecolorchange("HatModel", data.colors.headgear);
        
    };

    // Example loading function for face features
    loadeyesFeature = (file) => {
        const timestamp = new Date().getTime();
        this.loader.load(`/models/eye/${file}.glb?ts=${timestamp}`, (gltf) => {
            // Add gltf.scene to your avatar
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading eye model ${file}:`, error);
        });
    };

    loadeyebrowFeature = (file) => {
        this.loader.load(`/models/eyebrow/${file}.glb`, (gltf) => {
            // Add gltf.scene to your avatar
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading eyebrow model ${file}:`, error);
        });
    };
    
    loadbeardFeature = (file) => {
        this.loader.load(`/models/beard/${file}.glb`, (gltf) => {
            // Add gltf.scene to your avatar
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading beard model ${file}:`, error);
        });
    };

    loadhairFeature = (file) => {
        this.loader.load(`/models/hair/${file}.glb`, (gltf) => {
            // Add gltf.scene to your avatar
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading hair model ${file}:`, error);
        });
    };

    loadnoseFeature = (file) => {
        this.loader.load(`/models/nose/${file}.glb`, (gltf) => {
            // Add gltf.scene to your avatar
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading nose model ${file}:`, error);
        });
    };

    loadlipsFeature = (file) => {
        this.loader.load(`/models/lip/${file}.glb`, (gltf) => {
            // Add gltf.scene to your avatar
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading lips model ${file}:`, error);
        });
    };
    // Example loading function for body model
    loadBodyModel = (file) => {
        this.loader.load(`/models/torso/${file}.glb`, (gltf) => {
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading body model ${file}:`, error);
        });
    };
    
    loadFootwear = (file) => {
        this.loader.load(`/models/foot/${file}.glb`, (gltf) => {
            this.avatar.add(gltf.scene);
        }, undefined, (error) => {
            console.error(`Error loading footwear model ${file}:`, error);
        });
    };

    loadHeadModel = (file) => {
        this.loader.load(`/models/head/${file}.glb`, (gltf) => {
            this.avatar.add(gltf.scene);
            // Optionally, perform any additional setup here
        }, undefined, (error) => {
            console.error(`Error loading head model ${file}:`, error);
        });
    };

    componentDidMount(prevProps) {
        // console.log("====================================", this.props.currentHair)
        // if (this.props.currentHair !== prevProps.currentHair && this.props.currentHair) {
        //     console.log("currentHair prop updated in componentDidUpdate:", this.props.currentHair);
        //     this.getHatLibrary(this.props.currentHair);
        // }
        if (this.notInited === true) {
            this.props.updateCategory('torso')
            // Load the base model with defaultMeshes and defaultPose
            if(this.state.imageFiles !== null){
            this.preloadHeadModels();

            Object.keys(headDefaults).forEach(headKey => {
                const defaults = headDefaults[headKey];
                this.preloadDefaultFaceModels(defaults);
            });
        }
            axios.get("./models/poses/default.json").then(res => {
                this.setState({pose: res.data});
                window.loadDefaultMeshes(bones, res.data);
            });
            this.notInited = false;
        }
    }

    preloadHeadModels = () => {
        // Preload both narrow and wide head types
        const headTypes = ['narrow', 'wide'];
    
        headTypes.forEach(headType => {
            const headLibrary = this.getHeadLibraryByType(headType);
            console.log("headlibrary: selector", headLibrary)
            headLibrary.forEach(model => {
                this.preloadMeshes(model);  // Preload each model
            });
        });
    };

    preloadMeshes = (model) => {
        this.loader.load(
            "/models/head/" + model.file + ".glb",  // Make sure to set the correct path
            (glTF) => {
                console.log(`Preloaded model: ${model.name}`);
                // You can store the glTF scene, or the loaded data in a cache for future use
                model.loadedData = glTF.scene;  // Store the loaded scene or model for later use
            },
            undefined,
            (error) => {
                console.error(`Error loading model ${model.file}:`, error);
            }
        );
    };

    getHeadLibraryByType = (headType) => {
        if (headElements[headType]) {
            return headElements[headType];  // Return the library for the given head type
        } else {
            console.warn(`Unknown head type "${headType}". Returning default head elements.`);
            return headElements['narrow'];  // Default to narrow if the type is unknown
        }
    };

    getTorsoLibraryByType = (torsoType) => {
        if (torsoElements[torsoType]) {
            return torsoElements[torsoType];  // Return the library for the given torso type
        } else {
            console.warn(`Unknown torso type "${torsoType}". Returning default torso elements.`);
            return torsoElements['narrow'];  // Default to narrow if the type is unknown
        }
    };
     
    preloadDefaultFaceModels = (defaults) => {
        // Preload default beard
        this.loader.load(
            `/models/beard/${defaults.currentBeard}.glb`,
            (gltf) => {
                console.log(`Preloaded default beard: ${defaults.currentBeard}`);
                defaults.currentBeardData = gltf.scene;
            },
            undefined,
            (error) => {
                console.error(`Error loading default beard: ${defaults.currentBeard}`, error);
            }
        );
    
        // Preload default hair
        this.loader.load(
            `/models/hair/${defaults.currentHair}.glb`,
            (gltf) => {
                console.log(`Preloaded default hair: ${defaults.currentHair}`);
                defaults.currentHairData = gltf.scene;
            },
            undefined,
            (error) => {
                console.error(`Error loading default hair: ${defaults.currentHair}`, error);
            }
        );
    
        // Preload default nose
        this.loader.load(
            `/models/nose/${defaults.currentNose}.glb`,
            (gltf) => {
                console.log(`Preloaded default nose: ${defaults.currentNose}`);
                defaults.currentNoseData = gltf.scene;
            },
            undefined,
            (error) => {
                console.error(`Error loading default nose: ${defaults.currentNose}`, error);
            }
        );
    
        // Preload default eyes
        this.loader.load(
            `/models/eye/${defaults.currentEye}.glb`,
            (gltf) => {
                console.log(`Preloaded default eyes: ${defaults.currentEye}`);
                defaults.currentEyeData = gltf.scene;
            },
            undefined,
            (error) => {
                console.error(`Error loading default eyes: ${defaults.currentEye}`, error);
            }
        );
    
        // Preload default eyebrows
        this.loader.load(
            `/models/eyebrow/${defaults.currentEyebrow}.glb`,
            (gltf) => {
                console.log(`Preloaded default eyebrows: ${defaults.currentEyebrow}`);
                defaults.currentEyebrowData = gltf.scene;
            },
            undefined,
            (error) => {
                console.error(`Error loading default eyebrows: ${defaults.currentEyebrow}`, error);
            }
        );
    
        // Preload default lips
        this.loader.load(
            `/models/lip/${defaults.currentLip}.glb`,
            (gltf) => {
                console.log(`Preloaded default lips: ${defaults.currentLip}`);
                defaults.currentLipData = gltf.scene;
            },
            undefined,
            (error) => {
                console.error(`Error loading default lips: ${defaults.currentLip}`, error);
            }
        );
    };    

    preloadMeshesFromLibrary = (library, category) => {
        library.forEach((model) => {
            this.loader.load(
                `/models/${category}/${model.file}.glb`,  // Adjust the path for each category
                (glTF) => {
                    console.log(`Preloaded MeshesFromLibrary: ${model.name}`);
                    model.loadedData = glTF.scene;  // Cache the loaded model for later use
                },
                undefined,
                (error) => {
                    console.error(`Error loading model ${model.file}:`, error);
                }
            );
        });
    };

    handleHeadModelSelect = (selectedHeadModel) => {
        this.setState({ selectedHeadModel });
        // const headDefaultsForType = headDefaults[selectedHeadModel];
        // if (headDefaultsForType) {
        //     // Preload the default models for beard, hair, nose, eye, eyebrow, and lip
        //     this.preloadDefaultFaceModels(headDefaultsForType);
        // }
        console.log("selectedHeadModel", selectedHeadModel.file)
        // Preload all face models associated with this head
        const beardLibrary = this.getBeardLibrary(selectedHeadModel.file);
        const hairLibrary = this.getHairLibrary(selectedHeadModel.file);
        const noseLibrary = this.getNoseLibrary(selectedHeadModel.file);
        const eyeLibrary = this.getEyeLibrary(selectedHeadModel.file);
        const eyebrowLibrary = this.getEyebrowLibrary(selectedHeadModel.file);
        const lipLibrary = this.getLipLibrary(selectedHeadModel.file);
        // Preload all face models
        this.preloadMeshesFromLibrary(beardLibrary, 'beard');
        this.preloadMeshesFromLibrary(hairLibrary, 'hair');
        this.preloadMeshesFromLibrary(noseLibrary, 'nose');
        this.preloadMeshesFromLibrary(eyeLibrary, 'eye');
        this.preloadMeshesFromLibrary(eyebrowLibrary, 'eyebrow');
        this.preloadMeshesFromLibrary(lipLibrary, 'lip');
    };

    applyPose(file) {
        //Ajax in react
        axios.get("./models/poses/" + file + ".json").then(res => {
            this.setState({pose: res.data});
            window.loadPose(res.data, bones);
        });
    }

    handleBodyTypeChange = (bodyType) => {
        this.setState({ selectedBodyType: bodyType, currentBodyTypeIndex: 0});
    };

    handleHeadTypeChange = (headType, selectedHeadModel) => {
        this.setState({ selectedHeadType: headType, currentHeadTypeIndex: 0 });
    };

    handleBodyTypeNavigation = (direction) => {
        const { currentBodyTypeIndex } = this.state;
        const newIndex = currentBodyTypeIndex + direction;
        if (newIndex >= 0 && newIndex < this.getTorsoLibrary().length) {
            this.setState({ currentBodyTypeIndex: newIndex });
        }
    };

    handleHeadTypeNavigation = (direction) => {
        const { currentHeadTypeIndex } = this.state;
        const newIndex = currentHeadTypeIndex + direction;
        if (newIndex >= 0 && newIndex < this.getHeadLibrary().length) {
            this.setState({ currentHeadTypeIndex: newIndex });
        }
    };

    getBeardLibrary = (headMesh) => {
        if (!headMesh) return beardElements; // Default beard elements

        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return beardElements;
            case "ava_head_narrow_teardrop":
                return beardElements2;
            case "ava_head_narrow_kite":
                return beardElements3;
            case "ava_head_narrow_round":
                return beardElements4;
            case "ava_head_narrow_square":
                return beardElements5;
            case "ava_head_narrow_rectangle":
                return beardElements6;
            case "ava_head_wide_oblong":
                return wideBeardElements;
            case "ava_head_wide_teardrop":
                return wideBeardElements2;
            case "ava_head_wide_kite":
                return wideBeardElements3;
            case "ava_head_wide_round":
                return wideBeardElements4;
            case "ava_head_wide_square":
                return wideBeardElements5;
            case "ava_head_wide_rectangle":
                return wideBeardElements6;
            default:
                console.warn(`getBeardLibrary: Unknown headMesh "${headMesh}". Using default beard elements.`);
                return beardElements;
        }
    };

    // Get Hair Library based on head mesh
    getHairLibrary = (headMesh) => {
        if (!headMesh) return hairElements; // Default hair elements

        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return hairElements;
            case "ava_head_narrow_teardrop":
                return hairElements2;
            case "ava_head_narrow_kite":
                return hairElements3;
            case "ava_head_narrow_round":
                return hairElements4;
            case "ava_head_narrow_square":
                return hairElements5;
            case "ava_head_narrow_rectangle":
                return hairElements6;
            case "ava_head_wide_oblong":
                return wideHairElements;
            case "ava_head_wide_teardrop":
                return wideHairElements2;
            case "ava_head_wide_kite":
                return wideHairElements3;
            case "ava_head_wide_round":
                return wideHairElements4;
            case "ava_head_wide_square":
                return wideHairElements5;
            case "ava_head_wide_rectangle":
                return wideHairElements6;
            default:
                console.warn(`getHairLibrary: Unknown headMesh "${headMesh}". Using default hair elements.`);
                return hairElements;
        }
    };

    // Get Nose Library based on head mesh
    getNoseLibrary = (headMesh) => {
        if (!headMesh) return noseElements; // Default nose elements

        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return noseElements;
            case "ava_head_narrow_teardrop":
                return noseElements2;
            case "ava_head_narrow_kite":
                return noseElements3;
            case "ava_head_narrow_round":
                return noseElements4;
            case "ava_head_narrow_square":
                return noseElements5;
            case "ava_head_narrow_rectangle":
                return noseElements6;
            case "ava_head_wide_oblong":
                return noseElements;
            case "ava_head_wide_teardrop":
                return noseElements2;
            case "ava_head_wide_kite":
                return noseElements3;
            case "ava_head_wide_round":
                return noseElements4;
            case "ava_head_wide_square":
                return noseElements5;
            case "ava_head_wide_rectangle":
                return noseElements6;
            default:
                console.warn(`getNoseLibrary: Unknown headMesh "${headMesh}". Using default nose elements.`);
                return noseElements;
        }
    };

    // Get glasses Library based on head mesh
    getGlassesLibrary = (headMesh) => {
        if (!headMesh) return narrowGlassesElements1; // Default glasses elements

        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return narrowGlassesElements1;
            case "ava_head_narrow_teardrop":
                return narrowGlassesElements2;
            case "ava_head_narrow_kite":
                return narrowGlassesElements3;
            case "ava_head_narrow_round":
                return narrowGlassesElements4;
            case "ava_head_narrow_square":
                return narrowGlassesElements5;
            case "ava_head_narrow_rectangle":
                return narrowGlassesElements6;
            case "ava_head_wide_oblong":
                return wideGlassesElements1;
            case "ava_head_wide_teardrop":
                return wideGlassesElements2;
            case "ava_head_wide_kite":
                return wideGlassesElements3;
            case "ava_head_wide_round":
                return wideGlassesElements4;
            case "ava_head_wide_square":
                return wideGlassesElements5;
            case "ava_head_wide_rectangle":
                return wideGlassesElements6;
            default:
                console.warn(`getGlassesLibrary: Unknown headMesh "${headMesh}". Using default glasses elements.`);
                return narrowGlassesElements1;
        }
    };

    // Get Hat Library based on head mesh
    getHatLibrary = (headgear) => {
        if (!headgear) return hatAllElements; // Default hat elements
        if (headgear === "ava_head_all_bald_accessories_hat_1") {
            return baldHatElements; // Return hatbald elements
        } else {
            return hatAllElements; // Return hadall elements
        }
    };

    // Get Eye Library based on head mesh
    getEyeLibrary = (headMesh) => {
        if (!headMesh) return eyeElements; // Default eye elements
        console.log("++++++++ headMesh ++++++", headMesh)
        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return eyeElements;
            case "ava_head_narrow_teardrop":
                return eyeElements2;
            case "ava_head_narrow_kite":
                return eyeElements3;
            case "ava_head_narrow_round":
                return eyeElements4;
            case "ava_head_narrow_square":
                return eyeElements5;
            case "ava_head_narrow_rectangle":
                return eyeElements6;
            case "ava_head_wide_oblong":
                return eyeElements;
            case "ava_head_wide_teardrop":
                return eyeElements2;
            case "ava_head_wide_kite":
                return eyeElements3;
            case "ava_head_wide_round":
                return eyeElements4;
            case "ava_head_wide_square":
                return eyeElements5;
            case "ava_head_wide_rectangle":
                return eyeElements6;
            default:
                console.warn(`getEyeLibrary: Unknown headMesh "${headMesh}". Using default eye elements.`);
                return eyeElements;
        }
    };

    // Get Eyebrow Library based on head mesh
    getEyebrowLibrary = (headMesh) => {
        if (!headMesh) return eyebrowElements; // Default eyebrow elements

        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return eyebrowElements;
            case "ava_head_wide_oblong":
                return eyebrowElements;
            case "ava_head_narrow_teardrop":
                return eyebrowElements2;
            case "ava_head_wide_teardrop":
                return eyebrowElements2;
            case "ava_head_narrow_kite":
                return eyebrowElements3;
            case "ava_head_wide_kite":
                return eyebrowElements3;
            case "ava_head_narrow_round":
                return eyebrowElements4;
            case "ava_head_wide_round":
                return eyebrowElements4;
            case "ava_head_narrow_square":
                return eyebrowElements5;
            case "ava_head_wide_square":
                return eyebrowElements5;
            case "ava_head_narrow_rectangle":
                return eyebrowElements6;
            case "ava_head_wide_rectangle":
                return eyebrowElements6;
            default:
                console.warn(`getEyebrowLibrary: Unknown headMesh "${headMesh}". Using default eyebrow elements.`);
                return eyebrowElements;
        }
    };

    // Get Lip Library based on head mesh
    getLipLibrary = (headMesh) => {
        if (!headMesh) return lipElements; 

        switch (headMesh) {
            case "ava_head_narrow_oblong":
                return lipElements;
            case "ava_head_narrow_teardrop":
                return lipElements2;
            case "ava_head_narrow_kite":
                return lipElements3;
            case "ava_head_narrow_round":
                return lipElements4; 
            case "ava_head_narrow_square":
                return lipElements5;
            case "ava_head_narrow_rectangle":
                return lipElements6;
            case "ava_head_wide_oblong":
                return lipElements;
            case "ava_head_wide_teardrop":
                return lipElements2;
            case "ava_head_wide_kite":
                return lipElements3;
            case "ava_head_wide_round":
                return lipElements4;
            case "ava_head_wide_square":
                return lipElements5;
            case "ava_head_wide_rectangle":
                return lipElements6;
            default:
                console.warn(`getLipLibrary: Unknown headMesh "${headMesh}". Using default lip elements.`);
                return lipElements;
        }
    };

    handleMoveToNextStep = () => {
        const { currentStep, currentStepIndex, selectedBodyType, selectedHeadType, currentBodyTypeIndex, currentHeadTypeIndex } = this.state;
        const isLeft = false;
        const bones = [];
        this.updateProgress(3);
        if (currentStep === 'torso' && selectedBodyType) {
            const category = 'torso';
            const selectedTorso = this.getTorsoLibrary()[currentBodyTypeIndex];
            window.changeMesh(
                category,
                selectedTorso,
                isLeft,
                bones,
                this.state.pose
            );
            let loadedMeshes = this.props.loadedMeshes;
            loadedMeshes[category] = selectedTorso.file;
            this.props.updateMeshes(loadedMeshes);
            this.setState({ currentStep: 'head' });
        } else if (currentStep === 'head' && selectedHeadType) {
            const category = 'head';
            const selectedHead = this.getHeadLibrary()[currentHeadTypeIndex];
            this.handleHeadModelSelect(selectedHead);
            window.changeMesh(
                category,
                selectedHead,
                isLeft,
                bones,
                this.state.pose
            );
            let loadedMeshes = this.props.loadedMeshes;
            loadedMeshes[category] = selectedHead.file;
            this.props.updateMeshes(loadedMeshes);
            this.handleCloseModel();
        }
    };

    handleCloseModel = () => {
        this.closeModel = true;
        this.props.updateCategory("hair");
    };

    getHeadLibrary = () => {
        const { selectedHeadType } = this.state;
        let headData = headElements[selectedHeadType];
        return headData || [];
    };

    getTorsoLibrary = () => {
        const { selectedBodyType } = this.state;
        let torsoData = torsoElements[selectedBodyType];
        return torsoData || [];
    };

    handleElementClick = (category, index) => {
        this.setState((prevState) => ({
            selectedElements: {
                ...prevState.selectedElements,
                [category]: index,
            },
        }));
    };

    renderProgressBar = () => {
        const { currentStepIndex } = this.state;
    
        return (
            <div className="progress-bar-wrapper">
                <div className={`circlebox ${currentStepIndex > 1 ? 'filled' : currentStepIndex === 1 ? 'current' : ''}`}></div>
                <div className={`progress-line ${currentStepIndex > 1 ? 'filled' : ''}`}></div>
    
                <div className={`circlebox ${currentStepIndex > 2 ? 'filled' : currentStepIndex === 2 ? 'current' : ''}`}></div>
                <div className={`progress-line ${currentStepIndex > 2 ? 'filled' : ''}`}></div>
    
                <div className={`circlebox ${currentStepIndex > 3 ? 'filled' : currentStepIndex === 3 ? 'current' : ''}`}></div>
                <div className={`progress-line ${currentStepIndex > 3 ? 'filled' : ''}`}></div>
    
                <div className={`circlebox ${currentStepIndex > 4 ? 'filled' : currentStepIndex === 4 ? 'current' : ''}`}></div>
            </div>
        );
    };

    render() {
        const {  
            selectedBodyType, 
            selectedHeadType, 
            currentBodyTypeIndex, 
            currentHeadTypeIndex 
        } = this.state;
        const category = this.props.currentCategory;
        const { loadedMeshes } = this.props;
        const isLeft = this.props.isLeft;
        let library;
        let sideIndicator;

        switch (category) {
            case "head": 
                library = this.getHeadLibrary();
                break;
            case "torso":
                library = this.getTorsoLibrary();
                break;
            case "foot":
                library = footElements;
                break;
            case "pose":
                library = poseElements;
                break;
            case "stand":
                library = standElements;
                break;
            case "beard":
                library = this.getBeardLibrary(loadedMeshes.head);
                break;
            case "hair":
                library = this.getHairLibrary(loadedMeshes.head);
                break;
            case "nose":
                library = this.getNoseLibrary(loadedMeshes.head);
                break;
            case "glasses":
                library = this.getGlassesLibrary(loadedMeshes.head);
                break;
            case "hat":
                library = this.getHatLibrary(loadedMeshes.head);
                break;
            case "eyebrow":
                library = this.getEyebrowLibrary(loadedMeshes.head);
                break;
            case "eye":
                library = this.getEyeLibrary(loadedMeshes.head);
                break;
            case "lip":
                library = this.getLipLibrary(loadedMeshes.head);
                break;
            default:
                library = [];
        }
        let filteredLibrary = (library || []).filter(
            (element) => {
                return element.name.toLowerCase().indexOf(this.state.search) !== -1;
            }
        );        

        const bodyTypeSlider = selectedBodyType && (
            <div>
                <div className="margin-08 select-body-type-text">Pick your outfit</div>
            <div className="selector-slider">
                <button className="slider-left" onClick={() => this.handleBodyTypeNavigation(-1)}>
                    &lt; {/* Left chevron */}
                </button>
        
                <div className="slider-body">
                    {this.getTorsoLibrary()[currentBodyTypeIndex] && (
                        <img
                            src={`img/library/torso/${this.getTorsoLibrary()[currentBodyTypeIndex].img}`}
                            alt={this.getTorsoLibrary()[currentBodyTypeIndex].img}
                        />
                    )}
                </div>
        
                <button className="slider-right" onClick={() => this.handleBodyTypeNavigation(1)}>
                    &gt; {/* Right chevron */}
                </button>
            </div>
        </div>
        );
        
        const headTypeSlider = selectedHeadType && (
            <div>
                <div className="margin-08 select-body-type-text">Choose a face shape</div>
                <div className="selector-slider">
                    <button className="slider-left" onClick={() => this.handleHeadTypeNavigation(-1)}>
                        &lt; {/* Left chevron */}
                    </button>
        
                    <div className="slider-body">
                        {this.getHeadLibrary()[currentHeadTypeIndex] && (
                                    <img 
                                        src={`img/library/head/${this.getHeadLibrary()[currentHeadTypeIndex].img}`} 
                                        alt={this.getHeadLibrary()[currentHeadTypeIndex].img} 
                                    />                  
                        )}
                    </div>
        
                    <button className="slider-right" onClick={() => this.handleHeadTypeNavigation(1)}>
                        &gt; {/* Right chevron */}
                    </button>
                </div>
            </div>
        );
        

        const bodyTypeButtons = (
        <div>
            <div className="select-body-type-text">Please select your body type</div>
            <div className="body-icon">
                <div className="icon-container" onClick={() => {
                    this.handleBodyTypeChange("narrow");
                    this.updateProgress(2);
                    }}>
                    <img src="img/library/torso/narrow_torso.png" alt="Narrow torso" />
                    <div className={`type-selector ${this.state.selectedBodyType === "narrow" ? "selected" : ""}`}>
                        Thin
                    </div>
                </div>
                <div className="icon-container" onClick={() => {
                    this.handleBodyTypeChange("normal");
                    this.updateProgress(2);
                    }}>
                    <img src="img/library/torso/normal_torso.png" alt="Normal torso" />
                      <div className={`type-selector ${this.state.selectedBodyType === "normal" ? "selected" : ""}`}>
                        Medium
                    </div>
                </div>
                <div className="icon-container" onClick={() => {
                            this.handleBodyTypeChange("wide");
                            this.updateProgress(2);
                        }}>
                    <img src="img/library/torso/wide_torso.png" alt="Wide torso" />
                    <div
                        className={`type-selector ${this.state.selectedBodyType === "wide" ? "selected" : ""}`}>
                        Wide
                    </div>
                </div>
            </div>
        </div>
        );
        
        const headTypeButtons = (
            <div>
                <div className="select-body-type-text">Choose a narrow or wide face</div>
            <div className="head-icon">
                <div className="icon-container" onClick={() => {
                            this.handleHeadTypeChange("narrow");
                            this.updateProgress(4);
                        }}>
                    <img src="img/library/head/Head_Narrow.jpg" alt="Narrow head" />
                    <div className={`type-selector ${this.state.selectedHeadType === "narrow" ? "selected" : ""}`}>
                        Narrow
                    </div>
                </div>
                <div className="icon-container" onClick={() => {
                            this.handleHeadTypeChange("wide");
                            this.updateProgress(4);
                        }}>
                    <img src="img/library/head/Head_Wide.jpg" alt="Wide head" />
                    <div
                        className={`type-selector ${this.state.selectedHeadType === "wide" ? "selected" : ""}`} >
                        Wide
                    </div>
                </div>
            </div>
            </div>
        );
        
        const handleMoveToNextStepButton = (
            <div className="continue-btn-container" onClick={this.handleMoveToNextStep}>
                <span className="continue-btn">Continue</span>
            </div>
        );

        const selectedElementForCategory = this.state.selectedElements[category];

        const elementDiv = [];

        for (let i = 0; i < filteredLibrary.length; i++) {
            // console.log("elementdiv", elementDiv)
            const isSelected = selectedElementForCategory === i;
            // console.log("filteredLibrary", filteredLibrary)
            elementDiv.push(
                <div
                    className={`el ${isSelected ? 'selected' : ''}`}
                    key={i}
                    onClick={() => {
                        this.handleElementClick(category, i);
                        let meshType;
                        switch (category) {
                            case "torso":
                                meshType = "Torso";
                                break;
                            case "head":
                                meshType = "Head";
                                break;
                            case "beard":
                                meshType = "Beard";
                                break;
                            case "hair":
                                meshType = "HairModel";
                                break;
                            case "eye":
                                meshType = "EyeModel";
                                break;
                            case "nose":
                                meshType = "NoseModel";
                                break;
                            case "lip":
                                meshType = "LipModel";
                                break;
                            case "eyebrow":
                                meshType = "EyeBrowModel";
                                break;
                            default:
                                meshType = undefined;
                        }
                        if (filteredLibrary[i].premium) {
                            this.props.updatePopupMessage(
                                "Sorry, This is a premium object."
                            );
                            this.props.updatePopup(true);
                        } else {
                            if (category === "pose") {
                                this.applyPose(filteredLibrary[i].file);
                            } else if (category === "stand") {
                                window.changeStand(filteredLibrary[i].file);
                            } else {
                                this.props.updateLoading(true);
                                window.changeMesh(
                                    category,
                                    filteredLibrary[i],
                                    isLeft,
                                    bones,
                                    this.state.pose
                                );
                                let loadedMeshes = this.props.loadedMeshes;
                                loadedMeshes[meshType] = filteredLibrary[i].file;
                                this.props.updateMeshes(loadedMeshes);

                            }
                        }
                    }}
                >
                    <div className="img">
                        <img
                            src={
                                "img/library/" + category + "/" + filteredLibrary[i].img
                            }
                            alt={filteredLibrary[i].img}
                        />
                    </div>
                    <div className="unselectable el-name">
                        {filteredLibrary[i].name}
                    </div>
                </div>
            );
        }

        return (
            <div>
              <div>
                {elementDiv && (
                  (category !== "torso" && category !== "foot" && category !== "stand" && category !== "hair") ? (
                    <div className="abs right panel">
                      <div className="abs right right-side">
                        <div className="box">
                          <div className="abs top left selector-no-padding">
                            {elementDiv}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="abs right panel">
                      <div className="abs right right-side">
                        <div className="box-shiftright">
                          <div>
                            {elementDiv && (
                              (category === "hair" || category === "stand" || category === "foot") ? (
                                <div className="abs top left selector-no-padding">
                                  {elementDiv}
                                </div>
                              ) : (
                                // 1 start
                                this.closeModel && (
                                  <div className="onboarding-wrapper">
                                    <div className="onboarding-box">
                                    {this.renderProgressBar()}
                                    
                                      {this.state.currentStep === 'torso' && (
                                            <div>
                                                <div>
                                                    <div>
                                                {this.state.currentBodyTypeIndex === null && bodyTypeButtons}
                                                
                                                </div>
                                                
                                                </div>
                                                <div className="selector-slider">
                                                    {bodyTypeSlider}
                                                </div>
                                                {this.state.currentBodyTypeIndex !== null && handleMoveToNextStepButton}
                                            
                                            
                                        </div>
                                        )}
                                        {this.state.currentStep === 'imageupload' && (
                                        <div className="createAvatarWrapper">
                                            <div className="contentWrapper">
                                                <div className="createAvatarText">
                                                    <h4 className="uploadTitle">Upload Image to Create Your Avatar Model</h4>
                                                    {this.state.imageFiles.length <= 1 && (
                                                        <div className="uploadButtonWrapper">
                                                            <label className="uploadButton">
                                                                Choose File
                                                                <input
                                                                    type="file"
                                                                    accept="images/*"
                                                                    onChange={this.handleImageUpload}
                                                                    hidden
                                                                />
                                                            </label>
                                                        </div>
                                                    )}
                                                    {this.state.partLoading && (
                                                        <PartLoader
                                                            loading={this.state.partLoading}
                                                            updateLoading={(loading) => this.setState({ partLoading: loading })}
                                                        />
                                                    )}
                                                    <button
                                                        className="crateAvatarbtn"
                                                        onClick={() => this.createAvatar(selectedHeadType)}
                                                        disabled={this.state.imageFiles.length === 0}
                                                    >
                                                        Create Avatar
                                                    </button>
                                                </div>
                                                <div className="uploadedImagesWrapper">
                                                    {this.state.imageFiles && this.state.imageFiles.map((file, index) => (
                                                        <img
                                                            key={index}
                                                            src={URL.createObjectURL(file)}
                                                            alt={`Uploaded ${index + 1}`}
                                                            className="uploadedImage"
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {this.state.currentStep === 'head' && (
                                            <div>
                                                <div>
                                                {this.state.currentHeadTypeIndex === null && headTypeButtons}
                                                </div>
                                                <div className="selector-slider">
                                                    
                                                {this.state.currentHeadTypeIndex !== null && (
                                                    <div>
                                                <div className="createAvatarText">
                                                    <h4> Or upload Image to Create Your Avatar</h4>
                                                    <button
                                                        className="crateAvatarbtn"
                                                        onClick={this.avatarModel}
                                                        // disabled={this.state.imageFiles.length === 0}
                                                        >
                                                        Create Model
                                                    </button>                            
                                                    
                                                </div>
                                                </div>
                                                )}
                                                {this.state.currentHeadTypeIndex !== null &&
                                                    <div className="divider-line"></div>
                                                }
                                                    {headTypeSlider}
                                                </div>
                                                {this.state.currentHeadTypeIndex !== null && handleMoveToNextStepButton}
                                                {this.state.partLoading && (
                                                        <PartLoader
                                                            loading={this.state.partLoading}
                                                            updateLoading={(loading) => this.setState({ partLoading: loading })}
                                                        />
                                                    )}
                                            </div>
                                        
                                    )}
                                        </div>
                                    </div>
                                )
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          );
    }          
}

export default Selector;
