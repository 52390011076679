import React, {useState} from "react";
import { NavLink, Link } from 'react-router-dom';
import "../css/Sidebar.css"
import logo1 from '../logo1.svg';
import topicsData from '../library/topics.json';


const Sidebar = () => {
  const [openCategories, setOpenCategories] = useState({});

  const toggleCategory = (category) => {
      setOpenCategories(prevState => ({
          ...prevState,
          [category]: !prevState[category]
      }));
  };
    return (
        <div className="sidebar">
            <img className="left-logo" src={logo1} alt="none"></img>
      <div className='nav-links'>
          <div className="sidebar-menu">
            {/* <div className='nav-link'>
              <NavLink  to='/' className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' } >
                Home
              </NavLink>
            </div>
              <div className='nav-link'>
                <NavLink to='/brainstorm' className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' }
              >
                  Brainstorm
                </NavLink>
              </div> */}
              <div className='nav-link'>
            <div onClick={() => toggleCategory('tools')}>
              Tools
            </div>
            {openCategories['tools'] && (
              <ul>
                <li>
                  <NavLink to="/" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                    Avatar
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/tools/context-to-3d" className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                    Context to 3D
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
          <div className='nav-link'>
              <NavLink  to='/tools/SculptGL' className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link' } >
                SculptGl
              </NavLink>
            </div>
              {/* <div className='nav-link'>
                <NavLink to="/Forum" className={ ({isActive}) => isActive ? 'nav-link active' : 'nav-link'}
              >
                  Forum
                </NavLink>
              </div>
                <div>
                    {topicsData.map(({ category, topics }) => (
                        <div key={category}>
                            <div className='nav-link' onClick={() => toggleCategory(category)}>
                                {category}
                            </div>
                            {openCategories[category] && (
                                <ul>
                                    {topics.map(topic => (
                                        <li key={topic}>
                                            <Link  className="forum-topics" to={`/forum/${topic}`}>{topic}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    ))}
                </div> */}
            </div>
          </div>
        </div>
      )
    }

export default Sidebar